<template>
  <footer>
    <b-container>
      <b-row>
        <b-col cols="12">
          <ul>
            <li class="list-inline-item footer-xl footer-sm">
              <img
                class="logo"
                style="height: 40px; width: auto;"
                alt="Logo All Safety Rental"
                src="/img/logo.png"
              />
            </li>
            <li class="list-inline-item footer-xl footer-sm">
              &copy; {{ new Date() | moment('YYYY') }} All Safety Rental
            </li>
            <li class="list-inline-item footer-lg">
              <img
                class="logo"
                alt="Logo All Safety Rental"
                src="/img/logo.png"
              />
              &copy; {{ new Date() | moment('YYYY') }} All Safety Rental
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-container>
    <cookie-law theme="westvlaanderen" @accept="acceptCookies()">
      <div slot-scope="props" class="w-100">
        <b-container fluid class="pt-4 pb-3">
          <b-row>
            <b-col sm="12" lg="8">
              <p>
                Deze website gebruikt cookies om je beter te kunnen helpen en de
                website te verbeteren. Ga je akkoord met de cookies?
                <a href="https://www.tsa-bv.nl/privacy.htm">Meer info</a>
              </p>
            </b-col>
            <b-col class="text-center text-lg-right" md="12" lg="4">
              <a
                class="btn btn-outline-light mr-3 mb-lg-3 mb-xl-0"
                href="https://www.tsa-bv.nl/faq/disclaimer.htm"
                target="BLANK"
                >Meer info</a
              >
              <b-button
                class="mb-lg-3 mb-xl-0"
                variant="outline-light"
                @click="props.accept"
              >
                <span>Ik ga akkoord</span>
              </b-button>
              <b-button
                class="ml-3"
                variant="outline-light"
                @click="props.decline"
              >
                <span>Niet toestaan</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import { BCol, BContainer, BRow, BButton } from 'bootstrap-vue'
export default {
  components: { CookieLaw, BCol, BContainer, BRow, BButton },
  methods: {
    acceptCookies: function() {
      this.$store.commit('acceptCookies')
    }
  }
}
</script>
<style scoped lang="scss">
footer {
  .icon-link {
    a {
      color: #6c757d;
      font-size: 20px;
    }
  }
  background-color: white;
  font-family: 'Roboto';

  .footer-xl {
    display: inline-block;
  }

  .footer-lg,
  .footer-xs {
    display: none;
  }
  ul {
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  li {
    display: inline-flex;
    vertical-align: middle;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
  }

  .logo-title-in-one {
    display: none;
  }

  .seperator {
    height: 21px;
    width: 1px;
    background-color: #282828;
  }

  .logo {
    width: 86px;
    height: 36px;
  }
}
@media (max-width: 1200px) {
  footer {
    text-align: center;
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
  }
}

@media (max-width: 991px) {
  footer {
    .footer-xl {
      display: none;
    }
    .footer-lg {
      display: block;
    }
    .pl-4,
    .pr-3 {
      padding: 0 !important;
    }
    ul {
      li {
        width: 100% !important;
        text-align: center;
        margin: auto;
        display: block;
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 523px) {
  .footer-lg {
    display: none !important;
  }
  .footer-sm {
    display: inline-block !important;
  }
}
</style>
