<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'

export default {
  metaInfo: {
    title: `All Safety Rental`,
    titleTemplate: '%s | All Safety Rental',
    meta: [
      {
        name: `description`,
        content: `All Safety Rental RentMagic login`,
        vmid: 'og:meta-description'
      }
    ]
  },
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@import './assets/scss/cookiebar';
@import './assets/scss/calendar';
@import './assets/scss/type';

#app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
}
.item-planner {
  .nav-tabs .nav-item:first-child {
    a {
      min-width: 247px;
    }
  }
}

a.nav-link.active {
  font-weight: 600;
}

input.form-control.datepicker-input {
  height: 43px;
  border-radius: 0.5rem;
}

.navbar.bg-light {
  background-color: #edf0f1 !important;
}
.navbar-brand {
  a {
    color: black !important;
  }
  img {
    height: 20px;
  }
}
.page-item.active .page-link {
  color: #fff !important;
}
a,
.btn.btn-link {
  color: #d60c0c !important;
}

a.btn,
.pagelink {
  color: white !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}
.container {
  padding-left: 129.5px;
  padding-right: 129.5px;
}
header.toast-header {
  border: none;
  display: none;
}

.b-toast .toast {
  opacity: 1;
  background-color: #d60c0c !important;
  color: white;
  font-weight: 900;
}

.no-break {
  white-space: nowrap;
}
@import './assets/scss/bootstrap-variables';
@import 'node_modules/bootstrap/scss/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');

* {
  font-family: 'Gotham-Bold', 'sans-serif';
}
html,
body {
  background-color: #f6f6f6 !important;
}
</style>
