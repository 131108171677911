import store from '../state/store'

async function checkResponse(result) {
  if (
    result.data &&
    result.data.Message &&
    result.data.Message === 'Invalid authetication Token.' &&
    result.data === 'Customer is on hold.'
  ) {
    await store.commit('unsetCart')
    await store.commit('unsetUser', {
      reason: 'sessionExpired=true'
    })
    return 'Session expired'
  }

  return null
}

module.exports = { checkResponse }
