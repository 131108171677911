const { axiosCreate } = require('./Api')
const moment = require('moment')
const { checkResponse } = require('../controllers/errorHandler')

import store from '../state/store'

async function getCart() {
  const result = await axiosCreate.get(`cart/${store.getters.contactID}`, {
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  if (result.data) {
    store.commit('setCart', {
      cart: result.data
    })
  }

  store.commit('setDateTimeExpectedStart', {
    date: moment(result.data.DateTimeExpectedStart, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  store.commit('setDateTimeExpectedEnd', {
    date: moment(result.data.DateTimeExpectedEnd, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  store.commit('setDateTimeBusinessStart', {
    date: moment(result.data.DateTimeExpectedStart, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  store.commit('setDateTimeBusinessEnd', {
    date: moment(result.data.DateTimeExpectedEnd, 'YYYY-MM-DD').format(
      'DD-MM-YYYY'
    )
  })

  let cartItems = []

  if (result.data.CartItems && result.data.CartItems.Collection) {
    result.data.CartItems.Collection.forEach(cartItem => {
      cartItems.push({
        item: cartItem.Description,
        cartItemID: cartItem.CartItemID,
        price: cartItem.Price,
        amount: cartItem.Amount || 0,
        delete: cartItem.CartItemID,
        total: cartItem.Price,
        maxAdded: cartItem.MaxPerCustomerLeftover,
        maxPerCustomer: cartItem.MaxPerCustomer
      })
    })

    cartItems = cartItems.filter(function(obj) {
      return obj.item !== 'Administratiekosten'
    })
  }

  return cartItems
}

async function getBusinessHours({ start, end, warehouseID }) {
  const result = await axiosCreate.get(`/businessdays/BusinessHours`, {
    params: {
      contactID: store.getters.contactID,
      WarehouseID: warehouseID,
      Start: moment(start, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      End: moment(end, 'DD-MM-YYYY').format('YYYY-MM-DD')
    },
    headers: { authorization: store.getters.token }
  })

  if ((await checkResponse(result)) !== null) return

  let startHours = []
  let endHours = []

  for (let hourObject of result.data.BusinessHourStart) {
    startHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  for (let hourObject of result.data.BusinessHourEnd) {
    endHours.push({
      value: hourObject.BusinessHour,
      text: hourObject.BusinessHour
    })
  }

  const hours = {
    startHours,
    endHours
  }

  return hours
}

async function checkout({ notes, CUST_ActivityDescription }) {
  const result = await axiosCreate.post(
    `cart/${store.getters.contactID}/checkout`,
    { notes, CUST_ActivityDescription },
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCartSimple({ itemID, compositionID } = {}) {
  await checkCartAge()
  const result = axiosCreate.post(
    `custcart/${store.getters.contactID}/cartItems`,

    {
      ItemID: itemID,
      CompositionID: compositionID
    },

    { headers: { authorization: store.getters.token } }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function addToCart({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd,
  itemID,
  compositionID,
  amount
} = {}) {
  await checkCartAge()
  const result = axiosCreate.post(
    `cart/${store.getters.contactID}/cartitems`,
    [
      {
        WarehouseID: warehouseID,
        DateTimeBusinessStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        DateTimeBusinessEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        DateTimeExpectedStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        DateTimeExpectedEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        ItemID: itemID,
        CompositionID: compositionID,
        Amount: amount
      }
    ],
    { headers: { authorization: store.getters.token } }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

// Check if cart cache is older then a day. Carts expire at 12 AM
async function checkCartAge() {
  const cartCache = store.getters.cartCache
  const cartDate = moment(cartCache.DateTimeCreated, 'YYYY-MM-DD')
  if (moment(cartDate).isBefore(moment().format('YYYY-MM-DD'), 'day')) {
    await getCart()
  }
}

async function updateCartItem({ cartItemID, amount }) {
  try {
    const result = await axiosCreate.patch(
      `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
      {
        CartItemID: cartItemID,
        Amount: amount
      },
      {
        headers: { authorization: store.getters.token }
      }
    )

    if ((await checkResponse(result)) !== null) return

    return result
  } catch (error) {
    if ((await checkResponse(error)) !== null) return
    return false
  }
}

async function updateCart({
  warehouseID,
  businessHourStart,
  businessHourEnd,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const result = await axiosCreate.patch(
    `cart/${store.getters.contactID}`,
    {
      WarehouseID: warehouseID,
      BusinessHourStart: businessHourStart,
      BusinessHourEnd: businessHourEnd,
      DateTimeExpectedStart: moment(dateTimeBusinessStart, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      DateTimeExpectedEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      DateTimeBusinessStart: moment(dateTimeBusinessStart, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      ),
      DateTimeBusinessEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
        'YYYY-MM-DD'
      )
    },
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  await getCart()
  return result
}

async function deleteCartItem({ cartItemID }) {
  const result = axiosCreate.delete(
    `cart/${store.getters.contactID}/cartitems/${cartItemID}`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  if ((await checkResponse(result)) !== null) return

  return result
}

async function cartMutationCheck({
  warehouseID,
  dateTimeBusinessStart,
  dateTimeBusinessEnd
} = {}) {
  const result = await axiosCreate.get(
    `cart/${store.getters.contactID}/invalidCartItems`,
    {
      params: {
        warehouseID,
        dateTimeBusinessStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        dateTimeBusinessEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        ),
        dateTimeExpectedStart: moment(
          dateTimeBusinessStart,
          'DD/MM/YYYY'
        ).format('YYYY-MM-DD'),
        dateTimeExpectedEnd: moment(dateTimeBusinessEnd, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        )
      },
      headers: { authorization: store.getters.token }
    }
  )
  if ((await checkResponse(result)) !== null) return
  return result.data
}

module.exports = {
  getCart,
  updateCartItem,
  addToCart,
  deleteCartItem,
  checkout,
  getBusinessHours,
  updateCart,
  cartMutationCheck,
  addToCartSimple
}
