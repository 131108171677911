import Vue from 'vue'
import store from './state/store'
import App from './App.vue'
import router from './router'
import './registerServiceWorker'
import VueClazyLoad from 'vue-clazy-load'
import BreadCrumbs from '@/components/header/BreadCrumbs'
import VueMeta from 'vue-meta'

import onlyInt from 'vue-input-only-number'
import { ModalPlugin } from 'bootstrap-vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUserSecret,
  faShoppingCart,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faThList,
  faUserCircle,
  faExclamationCircle,
  faInfoCircle,
  faSignOutAlt,
  faUserEdit,
  faTh,
  faFilePdf,
  faPlus,
  faMinus,
  faEnvelope,
  faTrash,
  faEye,
  faSearchPlus,
  faEyeSlash,
  faSave,
  faTimes,
  faDownload,
  faEdit,
  faUserShield
} from '@fortawesome/free-solid-svg-icons'

import {
  faChevronDoubleRight,
  faCreditCard,
  faChevronDoubleLeft
} from '@fortawesome/pro-solid-svg-icons'

import { faSpinner } from '@fortawesome/pro-duotone-svg-icons'

import {
  faFacebookSquare,
  faTwitterSquare,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'

import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import '@/globalComponents'

library.add(
  faTh,
  faPlus,
  faMinus,
  faThList,
  faCreditCard,
  faSignOutAlt,
  faSearchPlus,
  faUserCircle,
  faUserSecret,
  faInfoCircle,
  faShoppingCart,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faFacebookSquare,
  faEnvelope,
  faTwitterSquare,
  faSave,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faTimesCircle,
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faUserEdit,
  faYoutube,
  faFilePdf,
  faSpinner,
  faTrash,
  faPlus,
  faTimes,
  faDownload,
  faEdit,
  faUserShield
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('bread-crumbs', BreadCrumbs)

Vue.use(require('vue-moment'))
Vue.use(ModalPlugin)
Vue.use(VueClazyLoad)
Vue.use(VueMeta)
Vue.use(onlyInt)

window.VueCalendarLang = function() {
  return {
    daysOfWeek: ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'],
    limit: 'Limit reached ({{limit}} items max).',
    loading: 'Laden...',
    minLength: 'Min. Lengte',
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'September',
      'October',
      'November',
      'December'
    ],
    notSelected: 'Niets geselecteerd',
    required: 'Vereist',
    search: 'Zoeken'
  }
}

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
