<template>
  <div class="breadcrumbs mt-3">
    <p>
      <span v-for="(breadCrumb, index) in breadCrumbs" :key="breadCrumb.index">
        <router-link :to="breadCrumb.path">{{ breadCrumb.title }}</router-link>

        <span
          v-if="
            index < breadCrumbs.length - 1 ||
              (index < breadCrumbs.length && currentpage)
          "
          class="seperator"
        >
          &nbsp; /
        </span>
      </span>
      <span class="currentpage">{{ currentpage }}</span>
    </p>
  </div>
</template>
<script>
export default {
  props: {
    breadCrumbs: {
      type: Array,
      required: false,
      default: function() {
        return [{ page: 'index', title: 'Mijn Account', path: '/Account' }]
      }
    },

    currentpage: {
      type: String,
      default: function() {
        return null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import url(//db.onlinewebfonts.com/c/9a2bc11be56851dde98c1b49302cc21a?family=Gotham+Medium);
p {
  font-size: 18px;
  font-family: Gotham-Medium, sans-serif;
  font-weight: 400;

  font-size: 0.6875rem;
  color: #0a0a0a;
  cursor: default;
  text-transform: uppercase;
}
.seperator {
  font-size: 14px;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 6px;
  padding-right: 6px;
  color: #333333;
}
.currentpage {
  color: #070808;
}
</style>
